import React from 'react';
import Stamp from '../images/stamp.png';
import Sign from '../images/sign.png';

const PlanFhd2020 = () => {
  return (
    <div className='p-8'>
      <div className='flex  flex-col text-right items-end mb-8'>
        <p className=' font-bold'>Утверждаю:</p>
        <p>Генеральный директор</p>
        <p>ЧОУ ДПО «Профф»</p>
        <div className='relative'>
          <img
            src={Stamp}
            alt=''
            className='absolute'
            style={{ left: -150, top: -90, width: 250 }}
          />
          <img
            src={Sign}
            alt=''
            className='w-20 h-20 absolute '
            style={{ left: 40, top: -40 }}
          />
          _________________ Е.С. Протопопова
        </div>
        <p>«25» декабря 2019 года</p>
      </div>

      <div className='p-8 text-center font-bold'>
        План финансово-хозяйственной деятельности на 2020 год
      </div>

      <p>г. Пыть-Ях</p>

      <div className='p-4 text-center font-bold'>
        Краткое содержание плана финансово - хозяйственной деятельности
      </div>
      <p>
        План финансово-хозяйственной деятельности Частного образовательного
        учреждения
      </p>
      <p>
        дополнительного профессионального образования «Профф» (далее
        «Учреждение») на 2020 г.
      </p>
      <p>является основанием для финансирования основной деятельности.</p>
      <p>
        В плане приведены общие сведения о деятельности Учреждения; общее
        описание
      </p>
      <p>
        ситуации, анализ существующего положения и перспектив развития
        Учреждения, описание
      </p>
      <p>
        маркетинговой политики Учреждения, характеристика оказываемых услуг,
        план основных
      </p>
      <p>
        параметров деятельности, план доходов, план по трудовым ресурсам,
        финансово -
      </p>
      <p>
        экономический план на 2020 г., отражены показатели по поступлениям и
        расходам по оказанию
      </p>
      <p>
        услуг, относящихся в соответствии с уставом Учреждения к его основным
        видам деятельности,
      </p>
      <p>предоставление которых осуществляется на платной основе.</p>
      <p>
        Источниками финансовых средств Учреждения является приносящая доход
        деятельность
      </p>
      <p>по оказанию образовательных услуг.</p>
      <p>Образование в Учреждении является полностью платным.</p>

      <div className='p-4 text-center font-bold'>Учетная карта предприятия</div>

      <table>
        <thead>
          <tr className='header'>
            <th className='border border-gray-700'>
              <strong>Краткое наименование контрагента</strong>
            </th>
            <th className='border border-gray-700'>
              <strong>ЧОУ ДПО «Профф»</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>Полное наименование</strong>
            </td>
            <td className='border border-gray-700'>
              Частное образовательное учреждение дополнительного
              профессионального образования «Профф»
            </td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>Генеральный директор</strong>
            </td>
            <td className='border border-gray-700'>
              Протопопова Елена Сергеевна
            </td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>
                Документ, на основании которого действует руководитель
              </strong>
            </td>
            <td className='border border-gray-700'>Устав</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>ИНН</strong>
            </td>
            <td className='border border-gray-700'>8612999016</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>Код причины постановки на учет (КПП)</strong>
            </td>
            <td className='border border-gray-700'>861201001</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>ОГРН</strong>
            </td>
            <td className='border border-gray-700'>1138600000296</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>ОКОПФ</strong>
            </td>
            <td className='border border-gray-700'>75500</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>ОКФС</strong>
            </td>
            <td className='border border-gray-700'>16</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>ОКПО</strong>
            </td>
            <td className='border border-gray-700'>21753979</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>ОКТМО</strong>
            </td>
            <td className='border border-gray-700'>71885000</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>ОКВЭД</strong>
            </td>
            <td className='border border-gray-700'>
              <p>
                <strong>85.42.1</strong> Деятельность школ подготовки водителей
                автотранспортных средств
              </p>
              <p>
                <strong>85.21</strong> Образование профессиональное среднее
              </p>
              <p>
                <strong>85.42</strong> Образование профессиональное
                дополнительное
              </p>
              <p>
                <strong>85.42.9</strong> Деятельность по дополнительному
                профессиональному образованию прочая, не включенная в другие
                группировки
              </p>
            </td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>Юридический адрес</strong>
            </td>
            <td className='border border-gray-700'>
              628383, РФ, Тюменская обл., ХМАО - Югра, г.Пыть-Ях, 6 мкр,
              «Пионерный», дом 30.
            </td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>Почтовый адрес</strong>
            </td>
            <td className='border border-gray-700'>
              628383, РФ, Тюменская обл., ХМАО - Югра, г.Пыть-Ях, 6 мкр,
              «Пионерный», дом 30.
            </td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>Фактический адрес</strong>
            </td>
            <td className='border border-gray-700'>
              628383, РФ, Тюменская обл., ХМАО - Югра, г.Пыть-Ях, 6 мкр,
              «Пионерный», дом 30
            </td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>Тел./факс электронный адрес</strong>
            </td>
            <td className='border border-gray-700'>
              8(3463)461-444, 89322575263; e-mail:{' '}
              <a href='mailto:proff461444@mail.ru'>proff461444@mail.ru</a>
            </td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>Наименование банка</strong>
            </td>
            <td className='border border-gray-700'>
              Ф-Л ЗАПАДНО-СИБИРСКИЙ ПАО БАНКА &#34;ФК ОТКРЫТИЕ&#34;
            </td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>БИК</strong>
            </td>
            <td className='border border-gray-700'>047162812</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>Расчетный счет</strong>
            </td>
            <td className='border border-gray-700'>40703810900090000075</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>Корреспондентский счет</strong>
            </td>
            <td className='border border-gray-700'>30101810465777100812</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>2. Общее описание ситуации</strong>
      </p>
      <p>
        Целью деятельности Учреждения является удовлетворение образовательных
      </p>
      <p>
        потребностей общества и граждан, осуществление образовательного
        процесса,
      </p>
      <p>
        направленного на удовлетворение образовательных и профессиональных
        потребностей,
      </p>
      <p>
        профессиональное развитие человека, обеспечение соответствия его
        квалификации
      </p>
      <p>
        меняющимся условиям профессиональной деятельности и социальной среды
        (обучение
      </p>
      <p>
        рабочим профессиям, подготовка, переподготовка и повышение квалификации
      </p>
      <p>
        специалистов и рабочих, а также пред аттестационная подготовка
        руководителей и
      </p>
      <p>
        специалистов) посредством реализации дополнительных профессиональных
        программ.
      </p>
      <p>Задачами Учреждения являются:</p>
      <p>- предоставление возможности гражданам в получении специальности и</p>
      <p>
        повышении квалификации в избранной области профессиональной
        деятельности;
      </p>
      <p>
        - удовлетворение потребностей общества в квалифицированных специалистах
      </p>
      <p>и рабочих профессиях;</p>
      <p>- распространение знаний среди населения, повышение его</p>
      <p>
        профессионального уровня, в том числе путем оказания платных
        образовательных услуг.
      </p>
      <p>- решение задач последовательного повышения профессионального</p>
      <p>
        мастерства, подготовка и переподготовка руководителей, специалистов и
        рабочих
      </p>
      <p>соответствующих профессий</p>
      <p>Учреждение в качестве основной цели своей деятельности осуществляет</p>
      <p>образовательную деятельность по программам, которые включают:</p>
      <p>- программы повышения квалификации;</p>
      <p>- программы профессиональной переподготовки;</p>
      <p>- программы профессионального обучения;</p>
      <p>- дополнительные общеобразовательные программы.</p>
      <p>Образование является полностью платным.</p>
      <p>
        Для осуществления указанных целей Учреждение осуществляет следующие виды
      </p>
      <p>деятельности:</p>
      <p>- оказание платных образовательных услуг в порядке, установленном</p>
      <p>законодательством Российской Федерации;</p>
      <p>
        - организация и проведение конференций, семинаров и иных мероприятий,
      </p>
      <p>
        необходимых для достижения целей, предусмотренных настоящим Уставом;
      </p>
      <p>
        - изучение, обобщение и распространение лучшего педагогического опыта,
      </p>
      <p>
        внедрение новых педагогических технологий, предоставление знаний в
        соответствии с
      </p>
      <p>образовательными программами;</p>
      <p>- разработка учебных планов и образовательных программ, оформление</p>
      <p>наглядных пособий;</p>
      <p>- осуществление иных видов деятельности, предусмотренных</p>
      <p>законодательством.</p>
      <p>
        Учреждение осуществляет образовательную деятельность в соответствии с
      </p>
      <p>
        лицензией № 2582 от 15.03.2016 года, выданной Службой по контролю и
        надзору в сфере
      </p>
      <p>образования Ханты Мансийского автономного округа – Югры.</p>
      <p>
        <strong>3. Структура управления</strong>
      </p>
      <p>Структура и компетенция органов управления Учреждением, порядок и</p>
      <p>
        формирование, сроки полномочий и порядок деятельности органов,
        определены уставом
      </p>
      <p>Учреждения в соответствии с законодательством Российской Федерации.</p>
      <p>Органами управления Учреждения являются:</p>
      <p>- учредитель;</p>
      <p>- генеральный директор;</p>
      <p>- педагогический совет;</p>
      <p>- общее собрание работников.</p>
      <p>
        <strong>
          4. Анализ существующего положения и перспектив развития Учреждения
        </strong>
      </p>
      <p>Общая характеристика существующего положения Учреждения:</p>
      <p>- Учреждение ведет свою деятельность в арендованном помещении.</p>
      <p>- Фактическая численность обучающихся за 2019 г. – 3784 человек.</p>
      <p>
        - Численность административно-управленческого персонала - 12 человек;
      </p>
      <p>- Численность педагогических работников - 35 человека;</p>
      <p>- Уровень образования педагогических и руководящих кадров:</p>
      <p>с высшим образованием - 30 человек.</p>
      <p>
        Стоимость обучения по каждой образовательной программе установлена в
        соответствии
      </p>
      <p>
        с Прейскурантом цен на 2020 год, утвержденным генеральным директором
        Учреждения.
      </p>
      <p>
        Учреждение ведет образовательную деятельность в течение календарного
        года.
      </p>
      <p>Обучение ведется на русском языке.</p>
      <p>
        Формы обучения и нормативные сроки обучения определяются соответствующей
      </p>
      <p>образовательной программой.</p>
      <p>
        Учреждение располагает аудиториями и компьютерными классами,
        оборудованными в
      </p>
      <p>
        соответствии с современными требованиям и оснащенными наглядными
        пособиями, макетами,
      </p>
      <p>тренажерами, компьютерами и мультимедийным оборудованием.</p>
      <p>
        Учреждение имеет библиотеку, в которой представлены необходимые для
        качественного
      </p>
      <p>
        обучения материалы, в том числе нормативная и техническая документация,
        методическая и
      </p>
      <p>справочная литература, периодические подписные издания.</p>
      <p>
        Для проведения занятий, актуализации имеющейся литературы и
        образовательных
      </p>
      <p>
        программ используется доступ к информационно-телекоммуникационной сети
        «Интернет».
      </p>
      <p>
        Для проверки знаний и самостоятельного изучения обучающимися необходимых
        материалов
      </p>
      <p>используется доступ к личному кабинету на сайте Учреждения.</p>
      <p>
        <strong>5. Перспективы развития Учреждения:</strong>
      </p>
      <p>
        - качественное оказание образовательных услуг по заявкам организаций и
        граждан;
      </p>
      <p>
        - повышение качества образовательного процесса и расширение
        инфраструктуры
      </p>
      <p>
        предлагаемых услуг и как следствие, повышение конкурентоспособности на
        рынке услуг;
      </p>
      <p>- повышение статуса среди организаций и населения;</p>
      <p>- привлечение квалифицированных сотрудников;</p>
      <p>- качественное улучшение материально-технической базы Учреждения;</p>
      <p>
        - повышение эффективности использования ресурсов своей деятельности.
      </p>
      <p>
        <strong>6. Маркетинговая деятельность Учреждения</strong>
      </p>
      <p>
        Учреждение ведет деятельность по изучению спроса на образовательные
        услуги и
      </p>
      <p>информированию населения о предоставляемых услугах.</p>
      <p>
        Для изучения спроса на дополнительные образовательные услуги проводится
      </p>
      <p>
        мониторинг изменений законодательства, опросы обучающихся и
        организаций-работодателей.
      </p>
      <p>
        C целью информирования организаций и граждан о деятельности Учреждением
        ведется
      </p>
      <p>
        сайт в сети «Интернет», осуществляются рассылки по электронной почте,
        размещаются
      </p>
      <p>публикации в средствах массой информации.</p>
      <p>
        <strong>7. Характеристика оказываемых услуг</strong>
      </p>
      <p>
        Учреждение предоставляет платные образовательные услуги в целях наиболее
        полного
      </p>
      <p>удовлетворения образовательных и социальных потребностей общества.</p>
      <p>
        Учреждение осуществляет образовательную деятельность по программам,
        которые
      </p>
      <p>включают:</p>
      <p>- программы повышения квалификации;</p>
      <p>- программы профессиональной переподготовки;</p>
      <p>- программы профессионального обучения;</p>
      <p>- дополнительные общеобразовательные программы.</p>
      <p>
        Платные образовательные услуги оказываются Учреждением в соответствии с
      </p>
      <p>
        Федеральным законом от 29 декабря 2012 г. № 273-ФЗ «Об образовании в
        Российской
      </p>
      <p>
        Федерации», Федеральным законом от 12.01.1996 № 7-ФЗ &#34;О
        некоммерческих организациях&#34;,
      </p>
      <p>
        Законом РФ от 07.02.1992 г. № 2300-1 &#34;О защите прав
        потребителей&#34;, Постановлением
      </p>
      <p>
        Правительства РФ от 15 августа 2013 г. № 706 «Об утверждении правил
        оказания платных
      </p>
      <p>образовательных услуг» и уставом Учреждения.</p>
      <p>
        <strong>8. План основных параметров деятельности</strong>
      </p>
      <p>
        <strong>
          Планируемые основные параметры деятельности на 2020 год:
        </strong>
      </p>
      <table>
        <thead>
          <tr className='header'>
            <th className='border border-gray-700'>
              <strong>№ п/п</strong>
            </th>
            <th className='border border-gray-700'>
              <strong>Наименование параметра</strong>
            </th>
            <th className='border border-gray-700'>
              <strong>Ед. изм.</strong>
            </th>
            <th className='border border-gray-700'>
              <strong>Количество</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='odd'>
            <td className='border border-gray-700'>1</td>
            <td className='border border-gray-700'>
              Численность обучающихся чел
            </td>
            <td className='border border-gray-700'>чел.</td>
            <td className='border border-gray-700'>5 500</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>2</td>
            <td className='border border-gray-700'>
              Нормативная наполняемость групп чел.
            </td>
            <td className='border border-gray-700'>чел.</td>
            <td className='border border-gray-700'>от 1 до 30</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Планируемые объемы доходов на 2020 год:</strong>
      </p>
      <table>
        <thead>
          <tr className='header'>
            <th className='border border-gray-700'>
              <p>
                <strong>Наименование услуги по</strong>
              </p>
              <p>
                <strong>видам</strong>
              </p>
            </th>
            <th className='border border-gray-700'>
              <p>
                <strong>Объем реализации</strong>
              </p>
              <p>
                <strong>в натуральных</strong>
              </p>
              <p>
                <strong>единицах</strong>
              </p>
            </th>
            <th className='border border-gray-700'>
              <p>
                <strong>Средняя цена за ед.,</strong>
              </p>
              <p>
                <strong>(руб.)</strong>
              </p>
            </th>
            <th className='border border-gray-700'>
              <p>
                <strong>Объем реализации,</strong>
              </p>
              <p>
                <strong>(руб)</strong>
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='odd'>
            <td className='border border-gray-700'>Основная деятельность</td>
            <td className='border border-gray-700'>5 500</td>
            <td className='border border-gray-700'>2 000,00</td>
            <td className='border border-gray-700'>10 000 000,00</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>Другие источники</td>
            <td className='border border-gray-700'>0</td>
            <td className='border border-gray-700'>0</td>
            <td className='border border-gray-700'>0</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>Всего:</td>
            <td className='border border-gray-700'>5 500</td>
            <td className='border border-gray-700'>2 000,00</td>
            <td className='border border-gray-700'>10 000 000,00</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Финансово - экономический план на 2020 год</strong>
      </p>
      <table>
        <thead>
          <tr className='header'>
            <th className='border border-gray-700'>
              <strong>№ п/п</strong>
            </th>
            <th className='border border-gray-700'>
              <strong>Наименование показателей</strong>
            </th>
            <th className='border border-gray-700'>
              <strong>Объем, тыс. руб.</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>1.</strong>
            </td>
            <td className='border border-gray-700'>
              <strong>Доходы, всего (без НДС)</strong>
            </td>
            <td className='border border-gray-700'>
              <strong>10 000</strong>
            </td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>
              <strong>2.</strong>
            </td>
            <td className='border border-gray-700'>
              <strong>Расходы, всего (сумма стр. 2.1-2.3)</strong>
            </td>
            <td className='border border-gray-700'>
              <strong>10 000</strong>
            </td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>2.1.</td>
            <td className='border border-gray-700'>
              <strong>Расходы на оплату труда</strong>
            </td>
            <td className='border border-gray-700'>
              <strong>2 350</strong>
            </td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-700'>2.2</td>
            <td className='border border-gray-700'>
              <strong>Начисления</strong>
            </td>
            <td className='border border-gray-700'>
              <strong>987</strong>
            </td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-700'>
              <strong>2.3</strong>
            </td>
            <td className='border border-gray-700'>
              <p>
                <strong>
                  Расходы на услуги сторонних организаций всего, в т.ч.:
                </strong>{' '}
                (Арендная плата
              </p>
              <p>Коммунальные расходы</p>
              <p>Электроэнергия</p>
              <p>Услуги связи (телефон, интернет)</p>
              <p>Расходы на содержание помещений (в т.ч. ремонт)</p>
              <p>Реклама</p>
              <p>Учебная литература</p>
              <p>Обслуживание сайта</p>
              <p>Охрана помещений</p>
              <p>Канцелярские товары</p>
              <p>Прочие услуги</p>
            </td>
            <td className='border border-gray-700'>
              <strong>6 663</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Генеральный директор Е.С. Протопопова</strong>
      </p>
    </div>
  );
};

export default PlanFhd2020;
