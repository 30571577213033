import React from 'react';

const data = [
  {
    title:
      'Объем образовательной деятельности, финансовое обеспечение которой осуществляется за счёт бюджетных ассигнований федерального бюджета:',
    value: 0,
  },
  {
    title:
      'Объём образовательной деятельности, финансовое обеспечение которой осуществляется за счёт бюджетов субъектов Российской Федерации:',
    value: 0,
  },
  {
    title:
      'Объём образовательной деятельности, финансовое обеспечение которой осуществляется за счёт местных бюджетов:',
    value: 0,
  },
  {
    title:
      'Объём образовательной деятельности, финансовое обеспечение которой осуществляется по договорам об образовании за счёт средств физических и (или) юридических лиц:',
    value: 100,
  },
];

const FinTable = () => {
  return (
    <div>
      <table className='border border-gray-700'>
        <thead>
          <tr className='border border-gray-700'>
            <th className='border border-gray-700  p-2'>
              ГРУППА СВЕДЕНИЙ ОБ ОБЪЁМЕ ФИНАНСОВОГО ОБЕСПЕЧЕНИЯ ОБРАЗОВАТЕЛЬНОЙ
              ОРГАНИЗАЦИИ
            </th>
            <th className='border border-gray-700 p-2'>ОБЪЁМ</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ title, value }) => (
            <tr key={title}>
              <td className='border border-gray-700 p-2'>{title}</td>
              <td className='border border-gray-700 p-2 text-center'>
                {value}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FinTable;
