import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import FinTable from "../components/financeTable";
import FinanceReport2020 from "../components/financeReport2020";
import Modal from "../components/modal";
import PlanFhd2019 from "../components/planFHD_2019";
import PlanFhd2020 from "../components/planFhd2020";
import PlanFhd2021 from "../components/planFhd2021";
import plan2022 from "../../static/PFHD_2022.pdf";

function Finances() {
  const [modalOpened, setModalOpened] = useState(false);
  const [plan2019ModalOpened, setPlan2019ModalOpened] = useState(false);
  const [plan2020ModalOpened, setPlan2020ModalOpened] = useState(false);
  const [plan2021ModalOpened, setPlan2021ModalOpened] = useState(false);

  const handleModalClose = () => {
    setModalOpened(false);
    setPlan2019ModalOpened(false);
    setPlan2020ModalOpened(false);
    setPlan2021ModalOpened(false);
  };

  return (
    <Layout>
      <SEO
        keywords={[
          `Финансовая деятельность`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title="Финансы"
      />

      <section className="flex flex-col items-center ">
        <h1 className=" text-center text-2xl uppercase tracking-wider text-green-800 font-bold mb-4">
          Финансовая деятельность
        </h1>
        <FinTable />
        <div
          className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
          onClick={() => setPlan2019ModalOpened(true)}
        >
          <h2 className="text-green-700 text-2xl text-center">
            План финансово-хозяйственной деятельности на 2019 год
          </h2>
        </div>

        <div
          className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
          onClick={() => setPlan2020ModalOpened(true)}
        >
          <h2 className="text-green-700 text-2xl text-center">
            План финансово-хозяйственной деятельности на 2020 год
          </h2>
        </div>

        <div
          className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
          onClick={() => setModalOpened(true)}
        >
          <h2 className="text-green-700 text-2xl text-center">
            Отчет о финансово-хозяйственной деятельности 2020
          </h2>
        </div>

        <div
          className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
          onClick={() => setPlan2021ModalOpened(true)}
        >
          <h2 className="text-green-700 text-2xl text-center">
            План финансово-хозяйственной деятельности на 2021 год
          </h2>
        </div>
        <div
          className="w-full bg-white p-2 mt-6 rounded shadow-lg cursor-pointer"
          // onClick={() => setPlan2021ModalOpened(true)}
        >
          <a href={plan2022} target="blank" rel="noopener noreferrer">
            <h2 className="text-green-700 text-2xl text-center">
              План финансово-хозяйственной деятельности на 2022 год
            </h2>
          </a>
        </div>
        {/* <Img fluid={data.structure2.childImageSharp.fluid} alt='Структура ' /> */}
      </section>
      {modalOpened && (
        <Modal onClose={handleModalClose}>
          <FinanceReport2020 />
        </Modal>
      )}
      {plan2019ModalOpened && (
        <Modal onClose={handleModalClose}>
          <PlanFhd2019 />
        </Modal>
      )}
      {plan2020ModalOpened && (
        <Modal onClose={handleModalClose}>
          <PlanFhd2020 />
        </Modal>
      )}
      {plan2021ModalOpened && (
        <Modal onClose={handleModalClose}>
          <PlanFhd2021 />
        </Modal>
      )}
    </Layout>
  );
}

export default Finances;
