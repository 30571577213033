import React from 'react';

import Stamp from '../images/stamp.png';
import Sign from '../images/sign.png';

const FinanceReport2020 = () => {
  return (
    <div className='p-8'>
      <div className='flex  flex-col text-right items-end mb-8'>
        <p className=' font-bold'>Утверждаю:</p>
        <p>Генеральный директор</p>
        <p>ЧОУ ДПО «Профф»</p>
        <div className='relative'>
          <img
            src={Stamp}
            alt=''
            className='absolute'
            style={{ left: -150, top: -90, width: 250 }}
          />
          <img
            src={Sign}
            alt=''
            className='w-20 h-20 absolute '
            style={{ left: 40, top: -40 }}
          />
          _________________ Е.С. Протопопова
        </div>
        <p> «21» января 2021 г.</p>
      </div>
      <div className='p-8 text-center font-bold'>
        Отчет о поступлении и расходовании финансовых и материальных средств
      </div>

      <p className='my-2'>
        1. Объем поступления финансовых средств по договорам об оказании платных
        образовательных услуг за счет физических и юридических лиц – 100%
      </p>
      <p className='my-2'>
        2. Объем поступления финансовых средств из других источников – 0 %
      </p>
      <p className='my-2'>
        3. О поступлении финансовых и материальных средств и об их расходовании
        ЧОУ ДПО «ПРОФФ» предоставляет отчеты в:
      </p>
      <p>
        - 1. Государственное учреждение – Управление Пенсионного фонда г.
        Пыть-Ях;
      </p>
      <p>
        - 2. УФК по Ханты-Мансийскому автономному округу Югре (Межрайонная ИФНС
        России №7 по ХМАО-Югре);
      </p>
      <p>
        - 3. УФК по ХМАО-Югре (ГУ-РО ФСС РФ по Ханты-Мансийскому автономному
        округу-Югре);
      </p>
      <p>
        - 4. Территориальный орган Федеральной службы государственной статистики
        по г. Нефтеюганск;
      </p>
      <p>- 5. Министерство юстиции;</p>
      <p>- 6. МКУ АДМИНИСТРАЦИЯ г. Пыть-Ях.</p>
      <table className='my-5 mx-auto'>
        <thead>
          <tr className='header'>
            <th className='border border-gray-600'>№ п/п</th>
            <th className='border border-gray-600'>Статья расхода</th>
            <th className='border border-gray-600'>самофинансирование</th>
            <th className='border border-gray-600'>бюджет и иные источники</th>
          </tr>
        </thead>
        <tbody>
          <tr className='odd'>
            <td className='border border-gray-600'>1.</td>
            <td className='border border-gray-600'>
              Доход от основного вида
              <br />
              деятельности
            </td>
            <td className='border border-gray-600'>100 %</td>
            <td className='border border-gray-600'>-</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-600'>2.</td>
            <td className='border border-gray-600'>Расходы:</td>
            <td className='border border-gray-600'>100 %</td>
            <td className='border border-gray-600'>-</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-600'>2.1.</td>
            <td className='border border-gray-600'>Заработная плата</td>
            <td className='border border-gray-600'>30 %</td>
            <td className='border border-gray-600'>-</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-600'>2.2.</td>
            <td className='border border-gray-600'>Отчисления с ФОТ</td>
            <td className='border border-gray-600'>15 %</td>
            <td className='border border-gray-600'>-</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-600'>2.3.</td>
            <td className='border border-gray-600'>Оплата аренды</td>
            <td className='border border-gray-600'>19 %</td>
            <td className='border border-gray-600'>-</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-600'>2.4.</td>
            <td className='border border-gray-600'>Налоги</td>
            <td className='border border-gray-600'>8 %</td>
            <td className='border border-gray-600'>-</td>
          </tr>
          <tr className='odd'>
            <td className='border border-gray-600'>2.5.</td>
            <td className='border border-gray-600'>
              Расходы на организацию
              <br />
              учебного процесса
            </td>
            <td className='border border-gray-600'>28 %</td>
            <td className='border border-gray-600'>-</td>
          </tr>
          <tr className='even'>
            <td className='border border-gray-600'>3.</td>
            <td className='border border-gray-600'>Остаток</td>
            <td className='border border-gray-600'>-</td>
            <td className='border border-gray-600'>-</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Исполнитель: Михманова Л.О. бухгалтер</strong>
      </p>
    </div>
  );
};

export default FinanceReport2020;
